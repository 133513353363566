<template>
  <v-container>
    <v-row>
      <v-col>
        <h2>Mis clientes</h2>
        <v-data-table
          dense
          :headers="headers"
          :items="proyectos"
        >
          <template v-slot:item="{ item }">
            <tr @dblclick="editar(item)">
              <td>{{ item.cliente }}</td>
              <td>{{ item.cliente }}</td>
              <td>{{ item.meta }}</td>
              <!-- <td>{{ item.fecharango }}</td> -->
              <td>{{ item.nomproy }}</td>
              <td>{{ item.numcli }}</td>
              <!-- <td>{{ item.numproy }}</td> -->
              <td>{{ item.obs }}</td>
              <td>{{ item.precio }}</td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data () {
    return {
      proyectos: [],
      headers: [
      
        { text: 'Id', value: 'idcliente'},
        { text: 'Cliente', value: 'cliente' },
        { text: 'Zona', value: 'zona' },
        { text: 'Clasificacion', value: 'clasificacion' },
        { text: 'Giro', value: 'Giro' },
        { text: 'Division', value: 'division' },
  
      ],
      
      // json_fields: {
      //   Estatus: "completo",
      //   Id: "idproyecto",
      //   FechaInicio: "inicio",
      //   FechaFin: "meta",

      //   NombreProyecto: "nomproy",
      //   NumCliente: "numcli",
      //   NumProyecto: "numproy",
      //   Observaciones: "obs",
      //   Precio: "precio",
      // },
      // json_meta: [
      //   [
      //     {
      //       key: "charset",
      //       value: "utf-8",
      //     },
      //   ],
      // ],
      // json_data: [],
    }
  },
}
</script>

<style>
</style>