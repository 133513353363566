<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card>
          <catmisclientes />
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-card elevation="10">
          <v-layout
            align-center
            justify-center
            fill-height
          >
            <v-flex>
              <v-tabs v-model="activeTab">
                <v-tab
                  key="tab-1"
                  to="/datospro"
                  ripple
                >
                  Datos
                </v-tab>

                <v-tab
                  key="tab-2"
                  to="/estadisticaspro"
                  ripple
                >
                  Estadisticas
                </v-tab>

                <v-tab
                  key="tab-3"
                  to="/preparacionpro"
                  ripple
                >
                  Preparacion de visita
                </v-tab>

                <v-tab
                  key="tab-4"
                  to="/agendarpro"
                  ripple
                >
                  Agendar
                </v-tab>

                <v-tab
                  key="tab-5"
                  to="/historialpro"
                  ripple
                >
                  Historial
                </v-tab>

                 

                <v-tab-item id="/datospro">
                  <router-view v-if="activeTab === '/datospro'" />
                </v-tab-item>

                <v-tab-item id="/estadisticaspro">
                  <router-view v-if="activeTab === '/estadisticaspro'" />
                </v-tab-item>
        
                <v-tab-item id="/preparacionpro">
                  <router-view v-if="activeTab === '/preparacionpro'" />
                </v-tab-item>

                <v-tab-item id="/agendarpro">
                  <router-view v-if="activeTab === '/agendarpro'" />
                </v-tab-item>

                <v-tab-item id="/historialpro">
                  <router-view v-if="activeTab === '/historialpro'" />
                </v-tab-item>
              </v-tabs>
            </v-flex>
          </v-layout>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import catmisclientes from '@/views/crmpro/misclientes/catmisclientes.vue'
export default {
  components: {
    catmisclientes,
    // datos
  },
  data: () => ({
    activeTab:'/legal',
    e1: 0,
    items: [
      { icon: 'mdi-book-edit-outline' ,text: 'Datos'        ,path: 'datos',color:"blue darken-2"},
      { icon: 'mdi-book-open'   , text: 'Estadisticas'        ,path: 'mapa'},
      { icon: 'mdi-chart-box'   , text: 'Preparacion de visita'       ,path: 'graficas'},
      { icon: 'mdi-content-copy', text: 'Agendar'   ,path: 'conordenes', color:"green darken-2"},
      { icon: 'mdi-content-copy', text: 'Historial'   ,path: 'conordenes', color:"green darken-2"},
    ],
      
    drawer: true,
    snackbar: false,
    isPaste: false,
    show1: false,
    password: '',
    email:'',
    rules: {
      required: value => !!value || 'Requerido.',
      min: v => v.length >= 8 || 'Minimo 8 caracteres',
      emailMatch: () => ('El correo y/o la contraseña no son correctos')
    }
  }),
  
}
</script>

<style>

</style>